export const addToCartAction = args => {
	return {
		type: 'add-to-cart',
		args
	};
};

export const removeFromCartAction = args => {
	return {
		type: 'remove-from-cart',
		args
	};
};

export const openCartWidgetAction = bool => {
	return {
		type: 'open-cart-widget',
		bool
	};
};

export const cartUpdateSuccessAction = message => {
	return {
		type: 'cart-update-success',
		message
	};
};

export const headerCartQuantityAction = headerCartQuantity => {
	return {
		type: 'header-cart-quantity',
		headerCartQuantity
	};
};

export const clearCartAction = () => {
	return {
		type: 'clear-cart'
	};
};
